import Success from "../../assets/staking/success.svg";
import Failed from "../../assets/staking/failed.svg";

const TransactionStatusModal = ({
  show,
  hideModal,
  status,
  amount,
  message,
}) => {
  return (
    <div
      className={`extend-increase-modal tnx-status-modal ${
        show ? "active" : ""
      }`}
    >
      <div className="modal-card">
        <img
          src={status === "success" ? Success : Failed}
          alt=""
          className="status-img"
        />

        {/* {Number(amount) > 0 && (
          <div
            className={`staked-synth-number ${
              status === "failed" ? "failed" : ""
            }`}
          >
            {amount} SYNTH
          </div>
        )} */}
        <div
          className={`staked-synth-number ${
            status === "failed" ? "failed" : ""
          }`}
        >
          {
            status === 'failed' ? 'Transaction error' : 'Transaction successful'
          }
        </div>

        <div className="note">{message}</div>

        <button className="close-button" onClick={hideModal}>
          <span>CLOSE</span>
        </button>
      </div>
    </div>
  );
};
export default TransactionStatusModal;
