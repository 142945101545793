import { useContext, useState } from "react";
import WalletIcon from "../../assets/staking/walletIcon.svg";
import { ConnectWallet } from "../ConnectWallet";
import { UserWalletContext } from "../../pages/contexts/userWalletContext";
import { formatNumberWithCommas } from "../../utils/formatNumberWithCommas";
import { ReactComponent as TotalStakedSynthIcon } from "../../assets/staking/total-stake-synth.svg";
import { ReactComponent as TotalValueLockedIcon } from "../../assets/staking/total-value-locked.svg";
import { ReactComponent as YourTotalStakeIcon } from "../../assets/staking/your-total-stake.svg";
import { ReactComponent as YourTotalRewardIcon } from "../../assets/staking/your-total-reward.svg";

const StakingDashboard = () => {
  const { totalSynthStaked, userStakeDetails, userTotalRewards } =
    useContext(UserWalletContext);

  return (
    <div className="staking-dashboard dashboard-section-box">
      <div className="d-flex justify-content-between align-items-center">
        <div className="section-title">Genesis staking dashboard</div>
        <div className="d-md-flex d-none">
          <ConnectWallet />
        </div>
      </div>

      <div className="total-staked-wrapper">
        <div className="box first">
          <div className="icon-container">
            <TotalStakedSynthIcon />
          </div>
          <div className="value">
            {formatNumberWithCommas(totalSynthStaked)} SYNTH
          </div>
          <div className="label">Total staked</div>
        </div>
        <div className="box second">
          <div className="icon-container">
            <TotalValueLockedIcon />
          </div>
          <div className="value">
            ${formatNumberWithCommas(totalSynthStaked * 1)}
          </div>
          <div className="label">Total VALUE LOCKED</div>
        </div>
        <div className="box third">
          <div className="icon-container">
            <YourTotalStakeIcon />
          </div>
          <div className="value">
            {formatNumberWithCommas(userStakeDetails.amount)} SYNTH
          </div>
          <div className="label">Your total stake</div>
        </div>
        <div className="box forth">
          <div className="icon-container">
            <YourTotalRewardIcon />
          </div>
          <div className="value">
            {formatNumberWithCommas(userTotalRewards)} SYNTH
          </div>
          <div className="label">Your total rewards</div>
        </div>
      </div>

      <div className="d-md-none d-flex">
        <ConnectWallet />
      </div>
    </div>
  );
};
export default StakingDashboard;
