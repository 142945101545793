import { useContext, useEffect, useState } from "react";
import StakeModal from "./StakeModal";
import TransactionStatusModal from "./TransactionStatusModal";
import IncreaseModal from "./IncreaseModal";
import { UserWalletContext } from "../../pages/contexts/userWalletContext";
import {
  formatNumber,
  formatNumberWithCommas,
} from "../../utils/formatNumberWithCommas";
import { fetchBalancePortion } from "../../utils/calculateAmount";
import {
  secondsToDays,
  activeStatus,
  getDaysHoursLeft,
  formatDateFromTimestampHyphenFormat,
} from "../../utils/formatDateFromTs";
import {
  getRelativeMonths,
  getSecondsValue,
} from "../../utils/getLockingPeriod";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { ReactComponent as FireIcon } from "../../assets/staking/fire.svg";
const pools = [
  {
    stakePeroid: 6,
    wp: "40%",
    cp: "15",
    apr: "20",
    poolsSize: "2,00,000",
    stakingMultiplayer: null,
  },
  {
    stakePeroid: 9,
    wp: "35%",
    cp: "12",
    apr: "45",
    poolsSize: "3,00,000",
    stakingMultiplayer: null,
  },
  {
    stakePeroid: 12,
    wp: "30%",
    cp: "9",
    apr: "200",
    poolsSize: "4,00,000",
    stakingMultiplayer: 2,
  },
  {
    stakePeroid: 18,
    wp: "25%",
    cp: "6",
    apr: "300",
    poolsSize: "5,00,000",
    stakingMultiplayer: 3,
  },
  // {
  //   stakePeroid: 50,
  //   wp: "40%",
  //   cp: "15",
  //   apr: "20",
  //   poolsSize: "1,000",
  //   stakingMultiplayer: 3,
  // },
];

const SynthPools = () => {
  const [selectedPool, setSelectedPool] = useState(6);
  const [selectedPoolIndex, setSelectedPoolIndex] = useState(0);
  const [transactionPopup, setTransactionPopup] = useState(false);
  const [transactionStatus, setTransactionSatus] = useState("");
  const [showIncreaseModal, setShowIncreaseModal] = useState(false);
  const [showStakeModal, setShowStakeModal] = useState(false);
  const [percentage, setPercentage] = useState(null);
  const [amount, setAmount] = useState(null);
  const [period, setPeriod] = useState(null);
  const [reward, setReward] = useState(0);
  const [apr, setApr] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [message, setMessage] = useState("");

  const {
    account,
    userStakingReward,
    balance,
    loading,
    process,
    handleLoading,
    getApproval,
    allowance,
    userLockDetails,
    stakeInPool,
    userStakeDetails,
    withdrawStakingRewards,
    withdrawPositionStake,
    coolDownDays,
    unStake,
    userNftReward,
    nftStakeAmt,
  } = useContext(UserWalletContext);

  useEffect(() => {
    if (Number(userStakeDetails.lockType)) {
      const val = getRelativeMonths(Number(userStakeDetails.lockType));
      const index = pools.findIndex((pool) => pool.stakePeroid === val);
      setSelectedPool(val);
      setSelectedPoolIndex(index);
    } else {
      setSelectedPool(6);
      setSelectedPoolIndex(0);
    }
  }, [account?.isConnected, userStakeDetails.lockType]);

  useEffect(() => {
    if (percentage > 0) {
      const portion = fetchBalancePortion(percentage, balance);
      setAmount(portion);
    }
  }, [percentage, trigger]);

  useEffect(() => {
    const val = parseFloat(amount) * (apr / 100) * (period / 12);
    setReward(val || 0);
  }, [amount, period, apr]);

  const handlePercentage = (val) => {
    setTrigger(!trigger);
    setPercentage(val);
  };

  const handleLockAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleStake = async () => {
    try {
      if (!amount || Number(amount) > Number(balance)) {
        setMessage(
          `Please ensure that you have sufficient funds for this transaction and try again. 
          If the issue still persists, please contact the team.`
        );
        setTransactionSatus("failed");
        setTransactionPopup(true);
        return;
      }

      if (
        Number(amount) + Number(userLockDetails[selectedPool]?.totalStaked) >
        Number(userLockDetails[selectedPool]?.maxPoolSize)
      ) {
        setMessage(
          `There is not enough capacity in the pool. Please adjust your amount and try again.`
        );
        setTransactionSatus("failed");
        setTransactionPopup(true);
        return;
      }
      handleLoading(true, "stake");
      if (allowance < amount) await getApproval(amount);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await stakeInPool(amount, getSecondsValue(period));
      handleLoading(false, "stake");
      setMessage(`The stake transaction has been successfully completed.`);
      setTransactionSatus("success");
      setTransactionPopup(true);
    } catch (e) {
      console.log(e?.details);
      handleLoading(false, "stake");
      setMessage(
        `There was an error in processing the stake transaction. 
        Please try again after a few minutes. 
        If the issue still persists, please contact the team.`
      );
      setTransactionSatus("failed");
      setTransactionPopup(true);
    }
  };

  const handleWithdraw = async () => {
    try {
      handleLoading(true, "stake-withdraw");
      await withdrawStakingRewards();
      handleLoading(false, "stake-withdraw");
      toast.success("Withdraw successfull");
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
      handleLoading(false, "stake-withdraw");
    }
  };

  const handlePoolWithdraw = async () => {
    if (coolDownDays && coolDownDays > Math.ceil(Date.now() / 1000)) {
      const { days, hours, minutes } = getDaysHoursLeft(coolDownDays);
      setMessage(
        !days && !hours && !minutes
          ? `Your cooling period ends in a few seconds.`
          : `Your cooling period ends in ${days} days ${hours} hours ${minutes} minutes.`
      );
      setTransactionSatus("failed");
      setTransactionPopup(true);

      return;
    }

    try {
      handleLoading(true, "position-withdraw");
      // Check if user has withdrawn NFT rewards
      if (Number(userNftReward) || Number(nftStakeAmt)) {
        setMessage(
          `Please unstake your NFT and withdraw your NFT pool rewards in order to proceed.`
        );
        setTransactionSatus("failed");
        setTransactionPopup(true);
        handleLoading(false, "position-withdraw");
        return;
      }

      await withdrawPositionStake();
      handleLoading(false, "position-withdraw");
      setMessage(`The withdraw transaction has been successfully completed.`);
      setTransactionSatus("success");
      setTransactionPopup(true);
    } catch (err) {
      console.log(err);
      setMessage(
        `There was an error in processing the withdraw transaction. 
        Please try again after a few minutes. 
        If the issue still persists, please contact the team.`
      );
      setTransactionSatus("failed");
      setTransactionPopup(true);
      handleLoading(false, "position-withdraw");
    }
  };

  const handleIncrease = async () => {
    try {
      if (!amount || Number(amount) > Number(balance)) {
        setMessage(
          `Please ensure that you have sufficient funds for this transaction and try again. 
          If the issue still persists, please contact the team.`
        );
        setTransactionSatus("failed");
        setTransactionPopup(true);
        return;
      }

      if (
        Number(amount) + Number(userLockDetails[selectedPool]?.totalStaked) >
        Number(userLockDetails[selectedPool]?.maxPoolSize)
      ) {
        setMessage(
          `There is not enough capacity in the pool. Please adjust your amount and try again.`
        );
        setTransactionSatus("failed");
        setTransactionPopup(true);
        return;
      }

      handleLoading(true, "increase");
      if (allowance < amount) await getApproval(amount);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await stakeInPool(
        amount,
        getSecondsValue(getRelativeMonths(Number(userStakeDetails.lockType)))
      );
      handleLoading(false, "increase");
      setMessage(`The increase transaction has been successfully completed.`);
      setTransactionSatus("success");
      setTransactionPopup(true);
      handleCloseIncreaseModal();
    } catch (e) {
      console.log(e);
      setMessage(
        `There was an error in processing the increase transaction. 
        Please try again after a few minutes. 
        If the issue still persists, please contact the team.`
      );
      setTransactionSatus("failed");
      setTransactionPopup(true);
      handleLoading(false, "increase");
    }
  };

  const handlePool = (apr, period, index) => {
    setShowStakeModal(true);
    setApr(Number(apr));
    setPeriod(Number(period));
  };

  const handleClosePool = () => {
    setShowStakeModal(false);
    setAmount("");
  };

  const handleSelectPool = (period, i) => {
    setSelectedPool(period);
    setSelectedPoolIndex(i);
  };

  const getPoolStakeStatus = (period) => {
    if (
      userLockDetails[period].maxPoolSize ===
      userLockDetails[period].totalStaked
    )
      return "Closed";
    else return "Stake";
  };

  const handleUnStake = async () => {
    try {
      handleLoading(true, "unstake");
      await unStake();
      handleLoading(false, "unstake");
      setMessage(`The unstake transaction has been successfully completed.`);
      setTransactionSatus("success");
      setTransactionPopup(true);
    } catch (err) {
      console.log(err?.details);
      handleLoading(false, "unstake");
      setMessage(
        `There was an error in processing the unstake transaction. 
        Please try again after a few minutes. 
        If the issue still persists, please contact the team.`
      );
      setTransactionSatus("failed");
      setTransactionPopup(true);
    }
  };

  const handleCloseIncreaseModal = () => {
    setShowIncreaseModal(false);
    setAmount("");
  };

  const handleIncreaseAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleTransactionDialog = () => {
    handleClosePool();
    setTransactionPopup(false);
    setTransactionSatus("");
    setMessage("");
  };

  return (
    <div className="synth-pools dashboard-section-box">
      <StakeModal
        show={showStakeModal}
        balance={balance}
        amount={amount}
        loading={loading}
        reward={reward}
        process={process}
        handleLoading={handleLoading}
        handlePercentage={handlePercentage}
        handleLockAmount={handleLockAmount}
        handleIncrease={handleStake}
        hideModal={handleClosePool}
      />

      <IncreaseModal
        balance={balance}
        amount={amount}
        loading={loading}
        process={process}
        handlePercentage={handlePercentage}
        handleLockAmount={handleIncreaseAmount}
        handleIncrease={handleIncrease}
        show={showIncreaseModal}
        hideModal={handleCloseIncreaseModal}
      />

      <TransactionStatusModal
        show={transactionPopup}
        hideModal={handleTransactionDialog}
        status={transactionStatus}
        amount={amount}
        message={message}
      />

      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div className="section-title">SYNTH pools</div>
          <div className="reward-wrapper d-md-none d-block">
            Rewards:{" "}
            <span className="green"> {formatNumberWithCommas(userStakingReward)} SYNTH </span>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div className="reward-wrapper d-md-flex d-none">
            Rewards:{" "}
            <span className="green">
              &nbsp; {formatNumberWithCommas(userStakingReward)} SYNTH{" "}
            </span>
          </div>
          {/* <div className="divider">|</div> */}
          {/* <button
            className="cta-button"
            disabled={account?.isDisconnected || coolDownDays}
            onClick={handleWithdraw}
          >
            {loading && process === "stake-withdraw" ? (
              <ThreeDots
                visible={true}
                height="18"
                width="60"
                color="#ffffff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass="center"
              />
            ) : (
              <span>Withdraw</span>
            )}
          </button> */}
        </div>
      </div>

      <div className="pools-wrapper">
        {pools.map((pool, index) => (
          <div
            key={`synth-${index}`}
            className={`box ${
              selectedPool === pool.stakePeroid ? "active" : ""
            } ${
              userLockDetails[pool.stakePeroid].maxPoolSize ===
                userLockDetails[pool.stakePeroid].totalStaked ||
              (getRelativeMonths(Number(userStakeDetails.lockType)) !==
                pool.stakePeroid &&
                Number(userStakeDetails.lockType))
                ? "disabled"
                : ""
            }`}
            onClick={() => handleSelectPool(pool.stakePeroid, index)}
          >
            <div className="d-flex align-items-center justify-content-center">
              <div className="pool-peroid">
                {pool.stakePeroid}{" "}
                {pool.stakePeroid === 50 ? "minutes" : "months"}
              </div>
              <div className="apr">{pool.apr}% APR</div>
            </div>

            {getRelativeMonths(Number(userStakeDetails.lockType)) !==
              pool.stakePeroid && (
              <button
                className="stake-button d-none d-md-flex"
                disabled={
                  account?.isDisconnected ||
                  Number(userStakeDetails.lockType) ||
                  userLockDetails[pool.stakePeroid].maxPoolSize ===
                    userLockDetails[pool.stakePeroid].totalStaked
                }
                onClick={() => handlePool(pool.apr, pool.stakePeroid, index)}
              >
                {getPoolStakeStatus(pool.stakePeroid)}
                {pool.stakingMultiplayer && (
                  <>
                    <span className="pool-stake-button-divider">|</span>{" "}
                    <FireIcon />{" "}
                    <span className="multiplayer">
                      x{pool.stakingMultiplayer}
                    </span>
                  </>
                )}
              </button>
            )}

            {coolDownDays &&
            pool.stakePeroid ===
              getRelativeMonths(Number(userStakeDetails.lockType)) ? (
              <button
                className="stake-button withdraw-button d-none d-md-flex"
                onClick={handlePoolWithdraw}
              >
                {loading && process === "position-withdraw" ? (
                  <ThreeDots
                    visible={true}
                    height="18"
                    width="60"
                    color="#ffffff"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass="center"
                  />
                ) : (
                  <span>Withdraw</span>
                )}
              </button>
            ) : getRelativeMonths(Number(userStakeDetails.lockType)) ===
              pool.stakePeroid ? (
              <>
                <div className="button-wrapper d-none d-md-flex">
                  {activeStatus(userStakeDetails.unlockEnd) && (
                    <button
                      className="stake-button"
                      disabled={
                        userLockDetails[
                          getRelativeMonths(userStakeDetails.lockType)
                        ]?.maxPoolSize ===
                          userLockDetails[
                            getRelativeMonths(userStakeDetails.lockType)
                          ]?.totalStaked ||
                        pool.stakePeroid !==
                          getRelativeMonths(
                            Number(userStakeDetails.lockType)
                          ) ||
                        userStakeDetails.unlockEnd <
                          Math.ceil(Date.now() / 1000)
                      }
                      onClick={() => setShowIncreaseModal(true)}
                    >
                      <span>Increase</span>
                    </button>
                  )}
                  <button
                    className={`stake-button  unstake-button ${
                      activeStatus(userStakeDetails.unlockEnd) ? "unstake" : ""
                    }`}
                    disabled={
                      coolDownDays ||
                      pool.stakePeroid !==
                        getRelativeMonths(Number(userStakeDetails.lockType))
                    }
                    onClick={handleUnStake}
                  >
                    {loading &&
                    process === "unstake" &&
                    selectedPool === pool.stakePeroid ? (
                      <ThreeDots
                        visible={true}
                        height="18"
                        width="60"
                        color="#ffffff"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass="center"
                      />
                    ) : (
                      <span>UNSTAKE</span>
                    )}
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>

      <div className="divider-border" />
      <div className="pool-details-wrapper">
        {coolDownDays || Number(userStakeDetails.lockType) > 0 ? (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <div className="label">Start date:</div>
              <div className="value">
                {formatDateFromTimestampHyphenFormat(
                  userStakeDetails.unlockEnd - userStakeDetails.lockType
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="label">End date:</div>
              <div className="value">
                {formatDateFromTimestampHyphenFormat(
                  userStakeDetails.unlockEnd
                )}
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="label">Early unstake penalty:</div>
              <div className="value">
                {userLockDetails[selectedPool]?.penalty}%
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="label">Cooling period:</div>
              <div className="value">
                {coolDownDays > Math.ceil(Date.now() / 1000)
                  ? `${getDaysHoursLeft(coolDownDays).days} days ${
                      getDaysHoursLeft(coolDownDays).hours
                    } hours ${getDaysHoursLeft(coolDownDays).minutes} minutes`
                  : Number(userStakeDetails.lockType) > 0 &&
                    Number(coolDownDays)
                  ? "Finished"
                  : userLockDetails[selectedPool]?.coolDownPeriod !== "600"
                  ? `${secondsToDays(
                      userLockDetails[selectedPool]?.coolDownPeriod
                    )} days`
                  : "10 minutes"}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <div className="label">Early unstake penalty:</div>
              <div className="value">
                {userLockDetails[selectedPool]?.penalty}%
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <div className="label">Cooling period:</div>
              <div className="value">
                {userLockDetails[selectedPool]?.coolDownPeriod !== "600"
                  ? `${secondsToDays(
                      userLockDetails[selectedPool]?.coolDownPeriod
                    )} days`
                  : "10 minutes"}
              </div>
            </div>
          </>
        )}
        <div className="d-flex align-items-center justify-content-between">
          <div className="label">Capacity:</div>
          <div>
            <div className="pool-size d-flex align-items-center">
              <span className="green">
                {formatNumber(userLockDetails[selectedPool]?.totalStaked)} SYNTH
              </span>{" "}
              <span className="grey">&nbsp;|&nbsp;</span>{" "}
              {formatNumber(userLockDetails[selectedPool]?.maxPoolSize)} SYNTH
            </div>
          </div>
        </div>
      </div>

      <div className="d-md-none">
        {getRelativeMonths(Number(userStakeDetails.lockType)) !==
          pools[selectedPoolIndex]?.stakePeroid && (
          <button
            className="pool-stake-button"
            onClick={() =>
              handlePool(
                pools[selectedPoolIndex].apr,
                pools[selectedPoolIndex].stakePeroid,
                selectedPoolIndex
              )
            }
            disabled={
              account?.isDisconnected ||
              Number(userStakeDetails.lockType) ||
              userLockDetails[pools[selectedPoolIndex].stakePeroid]
                .maxPoolSize ===
                userLockDetails[pools[selectedPoolIndex].stakePeroid]
                  .totalStaked
            }
          >
            {getPoolStakeStatus(pools[selectedPoolIndex].stakePeroid)}
            {pools[selectedPoolIndex].stakingMultiplayer && (
              <>
                <span className="pool-stake-button-divider">|</span>{" "}
                <FireIcon />{" "}
                <span className="multiplayer">
                  x{pools[selectedPoolIndex].stakingMultiplayer}
                </span>
              </>
            )}
          </button>
        )}
        <div className="button-wrapper">
          {pools[selectedPoolIndex].stakePeroid ===
            getRelativeMonths(Number(userStakeDetails.lockType)) &&
          coolDownDays ? (
            <button
              className="stake-button withdraw-button"
              onClick={handlePoolWithdraw}
            >
              {loading && process === "position-withdraw" ? (
                <ThreeDots
                  visible={true}
                  height="18"
                  width="60"
                  color="#ffffff"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass="center"
                />
              ) : (
                <span>Withdraw</span>
              )}
            </button>
          ) : (
            getRelativeMonths(Number(userStakeDetails.lockType)) ===
              pools[selectedPoolIndex]?.stakePeroid && (
              <>
                <button
                  className="pool-stake-button"
                  disabled={
                    userLockDetails[
                      getRelativeMonths(userStakeDetails.lockType)
                    ]?.maxPoolSize ===
                      userLockDetails[
                        getRelativeMonths(userStakeDetails.lockType)
                      ]?.totalStaked ||
                    pools[selectedPoolIndex].stakePeroid !==
                      getRelativeMonths(Number(userStakeDetails.lockType)) ||
                    userStakeDetails.unlockEnd < Math.ceil(Date.now() / 1000)
                  }
                  onClick={() => setShowIncreaseModal(true)}
                >
                  <span>Increase</span>
                </button>

                <button
                  className={`stake-button unstake-button ${
                    activeStatus(userStakeDetails.unlockEnd) ? "unstake" : ""
                  }`}
                  disabled={
                    coolDownDays ||
                    pools[selectedPoolIndex].stakePeroid !==
                      getRelativeMonths(Number(userStakeDetails.lockType))
                  }
                  onClick={handleUnStake}
                >
                  {loading && process === "unstake" ? (
                    <ThreeDots
                      visible={true}
                      height="18"
                      width="60"
                      color="#ffffff"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass="center"
                    />
                  ) : (
                    <span>UNSTAKE</span>
                  )}
                </button>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default SynthPools;
