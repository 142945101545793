import { useEffect, useState } from "react";

const HomeSection = () => {

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    setInterval(() => {
      updateTimes();
    }, 1000)
  }, [])

  const updateTimes = () => {
    const countDownDate = Date.UTC(2024, 4, 15, 23, 59, 0); 
    let now = new Date().getTime();
    let timeleft = countDownDate - now;
    let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));

    const d = (days > 9 ? days : '0' + days).toString();
    const h = (hours > 9 ? hours : '0'+ hours).toString();
    const m = (minutes > 9 ? minutes : '0' + minutes).toString();
    setDays(d);
    setHours(h);
    setMinutes(m);
  }

  return(
    <section className="sy-credits-top-section staking-top-section">
      
      <div className='site-container'>
        <div className="row justify-content-between">
          <div className="col-lg-8 col-12 order-2 order-lg-1">
            <div className='content-wrapper'>
              
              <div className='title'>
                GENESIS <br className="d-none d-md-block" /> STAKING PROGRAM
              </div>
              
              <div className='description'>
                Staking at least 1,000 SYNTH within the first 100 days of launch qualifies stakers for bonus rewards as part of the 100-day genesis staking program.
              </div>
              
              <div className="d-flex align-items-center">
                <a
                  href="https://synthr.gitbook.io/home/welcome/genesis-staking"
                  target="_blank"
                  className="learn-button"
                  rel="noreferrer"
                >
                  LEARN MORE
                </a>
                <a 
                  href="#stake"
                  className='stake-button'
                >
                  STAKE NOW
                </a>
              </div>

              {/* <div className="timer">Time to snapshot</div>
              <div className="d-flex">
                <div>
                  <div className="timer-value">{days}</div>
                  <div className="timer-label">{days > 1 ? 'Days' : "Day"}</div>
                </div>
                <div className="timer-colon">:</div>
                <div>
                  <div className="timer-value">{hours}</div>
                  <div className="timer-label">{hours > 1 ? 'Hours' : "Hour"}</div>
                </div>
                <div className="timer-colon">:</div>
                <div>
                  <div className="timer-value">{minutes}</div>
                  <div className="timer-label">{minutes > 1 ? 'Mins' : "Min"}</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}
export default HomeSection;