import { BigNumber } from "bignumber.js";

export function getBigNumberSum(num1, num2) {
  // Create BigNumber instances from the input numbers
  const bigNum1 = new BigNumber(num1);
  const bigNum2 = new BigNumber(num2);

  // Add the two BigNumber instances
  const sum = bigNum1.plus(bigNum2);

  // Return the result as a string (or use .toString() if needed)
  return Number(sum.toString());
}
