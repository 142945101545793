import StakingDashboard from "../../components/Staking/StakingDashboard";
import Footer from "../../components/NewFooter";
import SynthPools from "../../components/Staking/SynthPools";
import NftPools from "../../components/Staking/NFTpools";
// import ActivePositions from "../../components/Staking/ActivePositions";

const DashboardSection = () => {

  return(
    <section className="dashboard-section position-relative" id="stake">
      <div className="site-container dashboard-container">
        <StakingDashboard />
        <div className="divider-border d-md-none" />
        <SynthPools />
        <div className="divider-border d-md-none" />
        <NftPools />
        {/* <div className="divider-border" /> */}
        {/* <ActivePositions /> */}
      </div>

      <Footer bg="black" />
    </section>
  )
}
export default DashboardSection;