import axios from "axios";
import { subgraphEndPoint } from "./constants";

export async function fetchTokenId(account) {
  try {
    // GraphQL query string
    const query = `
      query MyQuery {
        transfers(
          where: {to: "${account}"}
          orderBy: blockTimestamp
          orderDirection: desc
          first: 1
        ) {
          tokenId
        }
      }
    `;

    // Axios POST request configuration for GraphQL API
    const response = await axios.post(subgraphEndPoint, { query });

    // Extract and return the tokenId from the GraphQL response
    const tokenId = response.data.data.transfers[0].tokenId;
    return Number(tokenId);
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Rethrow the error for handling in the calling code
  }
}
