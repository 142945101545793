import { useEffect, useState } from "react";
import { synthTokenAddress, synthrStakingAddress } from "../../utils/constants";
import { synthTokenABI } from "../../abis/SyntTokenABI";
import { useAccount, useReadContract } from "wagmi";
import { ethers } from "ethers";
import { useUserNft } from "./useUserNfts";
import { useUserPools } from "./useUserPools";
import { getBigNumberSum } from "../../utils/getBigNumberCalc";
import { synthrStakingABI } from "../../abis/SynthrStakingABI";

export function useUserWallet() {
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState("0");
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState("");
  const [totalSynthStaked, setTotalSynthStaked] = useState(0);
  const [userTotalRewards, setUserTotalRewards] = useState(0);

  const account = useAccount();
  const { userNftReward } = useUserNft();
  const { userStakingReward } = useUserPools();

  const { data: decimals } = useReadContract({
    address: synthTokenAddress,
    abi: synthTokenABI,
    functionName: "decimals",
  });

  const { data: synth } = useReadContract({
    address: synthTokenAddress,
    abi: synthTokenABI,
    functionName: "balanceOf",
    args: [account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  const { data: synthStaked } = useReadContract({
    address: synthrStakingAddress,
    abi: synthrStakingABI,
    functionName: "totalSupply",
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  useEffect(() => {
    !account?.isConnected ? setAddress("") : setAddress(account?.address);
    if (synth) getBalance();
  }, [account?.isConnected, account?.address, synth]);

  useEffect(() => {
    if (synthStaked) getTotalSynthStaked();
  }, [synthStaked]);

  useEffect(() => {
    if (account?.isConnected && (userStakingReward || userNftReward)) {
      getUserTotalRewards();
    }
  }, [
    account?.isConnected,
    account?.address,
    userNftReward,
    userStakingReward,
  ]);

  const getBalance = () => {
    const synthBalance = ethers.utils.formatUnits(synth, decimals);
    setBalance(synthBalance);
  };

  const handleLoading = (status, process) => {
    setLoading(status);
    setProcess(process);
  };

  const getTotalSynthStaked = () => {
    const totalStakedAmt = ethers.utils.formatUnits(
      synthStaked,
      decimals || 18
    );

    const parsedTotalStakedAmt = parseFloat(totalStakedAmt).toFixed(2);
    setTotalSynthStaked(parsedTotalStakedAmt);
  };

  const getUserTotalRewards = () => {
    const rewards = getBigNumberSum(userStakingReward, userNftReward);
    setUserTotalRewards(rewards);
  };

  const resetUserWallet = () => {
    setBalance(0);
    setAddress("");
    setUserTotalRewards(0);
  };

  return {
    account,
    address,
    balance,
    loading,
    process,
    totalSynthStaked,
    userTotalRewards,
    handleLoading,
    resetUserWallet,
  };
}
