export function formatDateFromTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString(undefined, options);
}

export function formatDateFromTimestampHyphenFormat(timestamp) {
  const date = new Date(timestamp * 1000);

  // Extract day, abbreviated month name, and last two digits of the year
  const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero if needed
  const monthAbbrev = date.toLocaleString("default", { month: "short" }); // Get abbreviated month name
  const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

  // Combine the components into the desired date format "DD-Mon-YY"
  const formattedDate = `${day}-${monthAbbrev}-${year}`;

  return formattedDate;
}

export function getTimeDuration(startTimestamp, endTimestamp) {
  const startDate = new Date(startTimestamp * 1000);
  const endDate = new Date(endTimestamp * 1000);

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const yearDifference = endYear - startYear;

  if (yearDifference === 0) {
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();
    if (endMonth - startMonth === 0) {
      return "0";
    } else {
      return `${endMonth - startMonth} months`;
    }
  } else if (yearDifference === 1) {
    return "1 year";
  } else {
    return `${yearDifference} years`;
  }
}

export function getTimeDurationFormat(time) {
  if (time === 0.5) {
    return `6 months`;
  } else if (time === 1) {
    return "1 year";
  } else {
    return `${time} years`;
  }
}

export function getYearCount(startTimestamp, endTimestamp) {
  const startDate = new Date(startTimestamp * 1000);
  const endDate = new Date(endTimestamp * 1000);

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();
  const yearDifferenceInMonths = (endYear - startYear) * 12;
  const monthDifference = endMonth - startMonth;

  const totalMonths = yearDifferenceInMonths + monthDifference;

  return totalMonths;
}

export function secondsToDays(seconds) {
  if (Number(seconds) === 3600) return 1;
  const secondsInDay = 86400; // Number of seconds in a day (24 hours * 60 minutes * 60 seconds)
  return Number(seconds) / secondsInDay;
}

export function activeStatus(seconds) {
  const currentTimestamp = Date.now(); // Get the current timestamp in milliseconds

  // Compare the provided timestamp with the current timestamp
  return currentTimestamp >= seconds;
}

export function getDaysHoursLeft(timestamp) {
  // Convert timestamp to milliseconds
  const currentTime = new Date().getTime();
  const targetTime = new Date(timestamp) * 1000;

  // Calculate the difference in milliseconds
  const difference = targetTime - currentTime;

  // Convert milliseconds to days and hours
  const daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesLeft = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  return { days: daysLeft, hours: hoursLeft, minutes: minutesLeft };
}
