// import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import SyCredits from './pages/SyCredits';
import Staking from "./pages/Staking";
// import { AnimatePresence } from "framer-motion";
// const Home = React.lazy(() => import('./pages/Home'));
// const SyCredits = React.lazy(() => import('./pages/SyCredits'));

const RoutesConfig = () => {
  return(
    <Router>
      {/* <Suspense fallback={<div style={{ fontSize: '8px' }}>Loading...</div>}> */}
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/sycredits" element={<SyCredits />} />
          <Route path="/staking" element={<Staking />} />
        </Routes>
      {/* </Suspense> */}
    </Router>
  )
}

export default RoutesConfig;