import { useContext, useEffect, useState } from "react";
import { UserWalletContext } from "../../pages/contexts/userWalletContext";
import { formatNumberWithCommas } from "../../utils/formatNumberWithCommas";
import { ThreeDots } from "react-loader-spinner";
import {
  stCrusaderAddress,
  stWizardAddress,
  stLegendAddress,
  stImmortalAddress,
} from "../../utils/constants";
import { fetchTokenId } from "../../utils/getTokenId";
import TransactionStatusModal from "./TransactionStatusModal";

const pools = [
  {
    poolName: "stCRUSADER",
    address: stCrusaderAddress,
    wp: "40%",
    cp: "15",
    apr: "5%",
    poolsSize: "2,00,000",
    isStaked: false,
    isClosed: false,
  },
  {
    poolName: "stWIZARD",
    address: stWizardAddress,
    wp: "35%",
    cp: "12",
    apr: "10%",
    poolsSize: "3,00,000",
    isStaked: false,
    isClosed: false,
  },
  {
    poolName: "stLEGEND",
    address: stLegendAddress,
    wp: "30%",
    cp: "9",
    apr: "15%",
    poolsSize: "4,00,000",
    isStaked: false,
    isClosed: false,
  },
  {
    poolName: "stIMMORTAL",
    address: stImmortalAddress,
    wp: "25%",
    cp: "6",
    apr: "25%",
    poolsSize: "5,00,000",
    isStaked: true,
    isClosed: false,
  },
];

const NftPools = () => {
  const [selectedPool, setSelectedPool] = useState(undefined);
  const [tokenId, setTokenId] = useState(undefined);
  const [message, setMessage] = useState("");
  const [transactionPopup, setTransactionPopup] = useState(false);
  const [transactionStatus, setTransactionSatus] = useState("");

  const {
    account,
    loading,
    process,
    handleLoading,
    stakeInNft,
    withdrawNftStake,
    userNftReward,
    ownedNft,
    unStakeInNft,
    nftStakeAmt,
    getNftApproval,
    userStakeDetails,
  } = useContext(UserWalletContext);

  useEffect(() => {
    const idx = pools.findIndex((pool) => pool.address === ownedNft);
    setSelectedPool(idx);
  }, [ownedNft]);

  useEffect(() => {
    if (account?.address) getTokenId();
  }, [account?.address]);

  const getTokenId = async () => {
    fetchTokenId(account.address)
      .then((id) => {
        setTokenId(id);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const handleNftStake = async () => {
    try {
      handleLoading(true, "nft-staking");
      await getNftApproval(tokenId);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await stakeInNft(ownedNft, tokenId);
      handleLoading(false, "nft-staking");
      setMessage(`The stake transaction has been successfully completed.`);
      setTransactionSatus("success");
      setTransactionPopup(true);
    } catch (err) {
      console.log(err);
      handleLoading(false, "nft-staking");
      setMessage(
        `There was an error in processing the stake transaction. 
        Please try again after a few minutes. 
        If the issue still persists, please contact the team.`
      );
      setTransactionSatus("failed");
      setTransactionPopup(true);
    }
  };

  const handleNftUnStake = async () => {
    try {
      handleLoading(true, "nft-unstake");
      await unStakeInNft();
      handleLoading(false, "nft-unstake");
      setMessage(`The unstake transaction has been successfully completed.`);
      setTransactionSatus("success");
      setTransactionPopup(true);
    } catch (err) {
      console.log(err);
      handleLoading(false, "nft-unstake");
      setMessage(
        `There was an error in processing the unstake transaction. 
        Please try again after a few minutes. 
        If the issue still persists, please contact the team.`
      );
      setTransactionSatus("failed");
      setTransactionPopup(true);
    }
  };

  const handleNftWithdraw = async () => {
    try {
      handleLoading(true, "nft-withdraw");
      await withdrawNftStake();
      handleLoading(false, "nft-withdraw");
      setMessage(`The withdraw transaction has been successfully completed.`);
      setTransactionSatus("success");
      setTransactionPopup(true);
    } catch (err) {
      console.log(err);
      handleLoading(false, "nft-withdraw");
      setMessage(
        `There was an error in processing the withdraw transaction. 
        Please try again after a few minutes. 
        If the issue still persists, please contact the team.`
      );
      setTransactionSatus("failed");
      setTransactionPopup(true);
    }
  };

  const handleTransactionDialog = () => {
    setTransactionPopup(false);
    setTransactionSatus("");
    setMessage("");
  };

  return (
    <div className="synth-pools dashboard-section-box">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <div className="section-title">NFT pools</div>
          <div className="reward-wrapper d-md-none d-block">
            Rewards: <span className="green">{formatNumberWithCommas(userNftReward)} SYNTH</span> 
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div className="reward-wrapper d-md-flex d-none">
            Rewards:{" "}
            <span className="green">
              &nbsp; {formatNumberWithCommas(userNftReward)} SYNTH{" "}
            </span>
          </div>
          <div className="divider">|</div>
          <button
            className="cta-button"
            onClick={handleNftWithdraw}
            disabled={
              loading || account?.isDisconnected || !Number(userNftReward)
            }
          >
            {loading && process === "nft-withdraw" ? (
              <ThreeDots
                visible={true}
                height="18"
                width="60"
                color="#ffffff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass="center"
              />
            ) : (
              <span>Withdraw</span>
            )}
          </button>
        </div>
      </div>

      <div className="pools-wrapper">
        {pools.map((pool, index) => (
          <div
            key={`nft-${index}`}
            className={`box ${index === selectedPool ? "active" : ""} ${
              pool.isClosed ? "disabled" : ""
            }`}
            onClick={() => setSelectedPool(index)}
          >
            <div className="d-flex align-items-center justify-content-center">
              <div className="pool-peroid">{pool.poolName}</div>
              <div className="apr blue">+{pool.apr} APR</div>
            </div>
            {ownedNft && Number(nftStakeAmt) && pool.address === ownedNft ? (
              <button
                className={`stake-button unstake-button d-none d-md-flex`}
                disabled={loading}
                onClick={handleNftUnStake}
              >
                {loading &&
                process === "nft-unstake" &&
                pool.address === ownedNft ? (
                  <ThreeDots
                    visible={true}
                    height="18"
                    width="60"
                    color="#ffffff"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass="center"
                  />
                ) : (
                  <span>UNSTAKE</span>
                )}
              </button>
            ) : (
              <button
                className={`stake-button d-none d-md-flex`}
                disabled={
                  ownedNft === "" ||
                  loading ||
                  (pool.address !== ownedNft && ownedNft !== "") ||
                  account?.isDisconnected ||
                  userStakeDetails.unlockEnd < Math.ceil(Date.now() / 1000)
                }
                onClick={handleNftStake}
              >
                {loading &&
                process === "nft-staking" &&
                pool.address === ownedNft ? (
                  <ThreeDots
                    visible={true}
                    height="18"
                    width="60"
                    color="#ffffff"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass="center"
                  />
                ) : (
                  <span>Stake</span>
                )}
              </button>
            )}
          </div>
        ))}
      </div>
      {ownedNft && Number(nftStakeAmt) && pools[selectedPool]?.address === ownedNft ? (
        <div className="button-wrapper">
          <button
            className={`stake-button unstake-button d-md-none`}
            disabled={loading}
            onClick={handleNftUnStake}
          >
            {loading &&
            process === "nft-unstake" &&
            pools[selectedPool]?.address === ownedNft ? (
              <ThreeDots
                visible={true}
                height="18"
                width="60"
                color="#ffffff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass="center"
              />
            ) : (
              <span>UNSTAKE</span>
            )}
          </button>
        </div>
      ) : (
        <button
          className="pool-stake-button d-md-none"
          onClick={handleNftStake}
          disabled={
            ownedNft === "" ||
            loading ||
            (pools[selectedPool]?.address !== ownedNft && ownedNft !== "") ||
            userStakeDetails.unlockEnd < Math.ceil(Date.now() / 1000) ||
            account?.isDisconnected
          }
        >
          {loading && process === "nft-staking" ? (
            <ThreeDots
              visible={true}
              height="18"
              width="60"
              color="#ffffff"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass="center"
            />
          ) : (
            <span>STAKE</span>
          )}
        </button>
      )}
      <TransactionStatusModal
        show={transactionPopup}
        hideModal={handleTransactionDialog}
        status={transactionStatus}
        message={message}
      />
    </div>
  );
};
export default NftPools;
