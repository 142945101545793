import { useEffect, useState } from "react";
import CreditTopSec from "../../assets/credit-top-bg.png";
import syCreditsService from "../../services/syCredits";
import AnimatedText from "../../components/AnimatedText";
import { inView } from "framer-motion";
import CountUp from "react-countup";

const HomeSection = () => {
  const [totalRewards, setTotalRewards] = useState(null);
  useEffect(() => {
    const title = document.querySelectorAll(".text-title-animation");
    title.forEach((ele) => {
      inView(ele, () => {
        addAnimation(ele)
        return () => addAnimation(ele,true);
      })
    })
  })

  const addAnimation = (ele, stop) => {
    const spanTags = ele.querySelectorAll("span")
    spanTags.forEach(spanTag => {
      if (stop) {
        spanTag.classList.remove("text-animation");
      } else {
        spanTag.classList.add("text-animation");
      }
    });
  }
  const getTotalRewards = async () => {
    await syCreditsService.getTotalRewards().then((res) => {
      const data = res.data;
      if(data.success){
        setTotalRewards(data.data);
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  // useEffect(() => {
  //   getTotalRewards();
  // }, [])

  return(
    <section className="sy-credits-top-section">
      
      <div className='site-container'>
        <div className="row justify-content-between top-section-wrapper">
          <div className="col-lg-8 col-12 order-2 order-lg-1">
            <div className='content-wrapper'>
              
              <div className='title text-title-animation'>
                CLIMB UP THE <br />
                COVETED LEADERBOARD
              </div>
                {/* <CountUp
                  start={1000000}
                  end={totalRewards?.total ? totalRewards.total : 22397971} 
                  duration="3"
                  delay='0'
                /> 
                <br /> syCREDITS EARNED */}

                
              
              <div className='description'>
                Quantify your contribution to SYNTHR's early days by earning syCREDITS, with leaderboard standings directly corresponding to various community benefits. You can boost your syCREDITS by referring users, claiming badges, and winning roles.
              </div>
              
              <div className="d-flex align-items-center">
                <a
                  href="https://synthr.gitbook.io/home/sycredits"
                  target="_blank"
                  className="learn-more-button"
                >
                  LEARN MORE
                </a>
                <a 
                  href="#sy-credits"
                  className='leaderboard-button'
                  // onClick={handleOpenApp}
                >
                  View Leaderboard
                </a>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img className="credit-bg" src={CreditTopSec} alt='' /> */}

    </section>
  )
}
export default HomeSection;