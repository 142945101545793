import { useEffect, useState } from "react";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { SyNftABI } from "../../abis/SyNFTABI";
import {
  nftStakingAddress,
  stCrusaderAddress,
  stImmortalAddress,
  stLegendAddress,
  stWizardAddress,
  synthTokenAddress,
} from "../../utils/constants";
import { nftStakingABI } from "../../abis/NFTStakingABI";
import { ethers } from "ethers";
import { synthTokenABI } from "../../abis/SyntTokenABI";

export function useUserNft() {
  const [userNftReward, setUserNftReward] = useState(0);
  const [nftStakeAmt, setNftStakeAmt] = useState(0);
  const [ownedNft, setOwnedNft] = useState("");

  const account = useAccount();
  const { writeContractAsync } = useWriteContract();

  const { data: decimals } = useReadContract({
    address: synthTokenAddress,
    abi: synthTokenABI,
    functionName: "decimals",
  });

  const { data: stImmortal } = useReadContract({
    address: stImmortalAddress,
    abi: SyNftABI,
    functionName: "balanceOf",
    args: [account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 5000,
    },
  });

  const { data: stCrusader } = useReadContract({
    address: stCrusaderAddress,
    abi: SyNftABI,
    functionName: "balanceOf",
    args: [account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 5000,
    },
  });

  const { data: stLegend } = useReadContract({
    address: stLegendAddress,
    abi: SyNftABI,
    functionName: "balanceOf",
    args: [account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 5000,
    },
  });

  const { data: stWizard } = useReadContract({
    address: stWizardAddress,
    abi: SyNftABI,
    functionName: "balanceOf",
    args: [account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 5000,
    },
  });

  const { data: nftPendingReward } = useReadContract({
    address: nftStakingAddress,
    abi: nftStakingABI,
    functionName: "pendingReward",
    args: [ownedNft, account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  const { data: userInfoWizard } = useReadContract({
    address: nftStakingAddress,
    abi: nftStakingABI,
    functionName: "userInfo",
    args: [stWizardAddress, account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  const { data: userInfoLegend } = useReadContract({
    address: nftStakingAddress,
    abi: nftStakingABI,
    functionName: "userInfo",
    args: [stLegendAddress, account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  const { data: userInfoImmortal } = useReadContract({
    address: nftStakingAddress,
    abi: nftStakingABI,
    functionName: "userInfo",
    args: [stImmortalAddress, account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  const { data: userInfoCrusader } = useReadContract({
    address: nftStakingAddress,
    abi: nftStakingABI,
    functionName: "userInfo",
    args: [stCrusaderAddress, account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  useEffect(() => {
    if (account?.isConnected) {
      getNftAddress();
    }
  }, [
    account?.isConnected,
    account?.address,
    stImmortal,
    stWizard,
    stLegend,
    stCrusader,
  ]);

  useEffect(() => {
    if (
      account?.isConnected &&
      userInfoWizard?.length > 0 &&
      userInfoLegend?.length > 0 &&
      userInfoImmortal?.length > 0 &&
      userInfoCrusader?.length > 0
    ) {
      getStakedNftAddress();
    }
  }, [
    account?.isConnected,
    account?.address,
    nftStakeAmt,
    userInfoWizard,
    userInfoLegend,
    userInfoImmortal,
    userInfoCrusader,
  ]);

  useEffect(() => {
    if (account?.isConnected) getUserNftRewards();
  }, [account?.isConnected, account?.address, nftPendingReward]);

  const getNftApproval = async (tokenId) => {
    await writeContractAsync({
      address: ownedNft,
      abi: SyNftABI,
      functionName: "approve",
      args: [nftStakingAddress, tokenId],
    });
  };

  const withdrawNftStake = async () => {
    await writeContractAsync({
      address: nftStakingAddress,
      abi: nftStakingABI,
      functionName: "claim",
      args: [ownedNft, account?.address],
    });
  };

  const unStakeInNft = async () => {
    await writeContractAsync({
      address: nftStakingAddress,
      abi: nftStakingABI,
      functionName: "withdrawAndClaim",
      args: [ownedNft, account?.address],
    });
  };

  const stakeInNft = async (pool, tokenId) => {
    await writeContractAsync({
      address: nftStakingAddress,
      abi: nftStakingABI,
      functionName: "deposit",
      args: [pool, tokenId],
    });
  };

  const getNftAddress = () => {
    if (Number(stImmortal)) {
      setOwnedNft(stImmortalAddress);
      setNftStakeAmt(0);
    } else if (Number(stWizard)) {
      setOwnedNft(stWizardAddress);
      setNftStakeAmt(0);
    } else if (Number(stLegend)) {
      setOwnedNft(stLegendAddress);
      setNftStakeAmt(0);
    } else if (Number(stCrusader)) {
      setOwnedNft(stCrusaderAddress);
      setNftStakeAmt(0);
    } else {
      setOwnedNft("");
      setNftStakeAmt(0);
    }
  };

  const getStakedNftAddress = () => {
    if (Number(userInfoImmortal[0]?.toString())) {
      setOwnedNft(stImmortalAddress);
      setNftStakeAmt(userInfoImmortal[0]?.toString());
    } else if (Number(userInfoWizard[0]?.toString())) {
      setOwnedNft(stWizardAddress);
      setNftStakeAmt(userInfoWizard[0]?.toString());
    } else if (Number(userInfoLegend[0]?.toString())) {
      setOwnedNft(stLegendAddress);
      setNftStakeAmt(userInfoLegend[0]?.toString());
    } else if (Number(userInfoCrusader[0]?.toString())) {
      setOwnedNft(stCrusaderAddress);
      setNftStakeAmt(userInfoCrusader[0]?.toString());
    } else {
      setNftStakeAmt(0);
    }
  };

  const getUserNftRewards = () => {
    const nftReward = ethers.utils.formatUnits(
      nftPendingReward || 0,
      decimals || 18
    );

    const parsedNftReward = parseFloat(nftReward || 0).toFixed(4);
    setUserNftReward(parsedNftReward);
  };

  const resetUserNfts = () => {
    setUserNftReward(0);
    setOwnedNft("");
    setNftStakeAmt(0);
  };

  return {
    userNftReward,
    ownedNft,
    nftStakeAmt,
    stakeInNft,
    unStakeInNft,
    withdrawNftStake,
    getNftApproval,
    resetUserNfts,
  };
}
