import { useEffect, useState } from "react";
import { lockDetailsObject } from "../../utils/initialState";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import {
  monthInSeconds,
  synthTokenAddress,
  synthrStakingAddress,
} from "../../utils/constants";
import { synthrStakingABI } from "../../abis/SynthrStakingABI";
import { ethers } from "ethers";
import { synthTokenABI } from "../../abis/SyntTokenABI";

export function useUserPools() {
  const [userStakingReward, setUserStakingReward] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [coolDownDays, setCoolDownDays] = useState(0);
  const [userStakeDetails, setUserStakeDetails] = useState({
    amount: 0,
    lockType: 0,
    unlockEnd: 0,
    rewardDebt: 0,
  });
  const [userLockDetails, setUserLockDetails] = useState({
    50: lockDetailsObject, // Hours
    6: lockDetailsObject,
    9: lockDetailsObject,
    12: lockDetailsObject,
    18: lockDetailsObject,
  });

  const account = useAccount();
  const { writeContractAsync } = useWriteContract();

  const { data: decimals } = useReadContract({
    address: synthTokenAddress,
    abi: synthTokenABI,
    functionName: "decimals",
  });

  const { data: userInfo } = useReadContract({
    address: synthrStakingAddress,
    abi: synthrStakingABI,
    functionName: "userInfo",
    args: [account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  const { data: userLockInfo50m } = useReadContract({
    address: synthrStakingAddress,
    abi: synthrStakingABI,
    functionName: "lockInfo",
    args: [monthInSeconds[50]],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 20000,
    },
  });

  const { data: userLockInfo6M } = useReadContract({
    address: synthrStakingAddress,
    abi: synthrStakingABI,
    functionName: "lockInfo",
    args: [monthInSeconds[6]],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 20000,
    },
  });

  const { data: userLockInfo9M } = useReadContract({
    address: synthrStakingAddress,
    abi: synthrStakingABI,
    functionName: "lockInfo",
    args: [monthInSeconds[9]],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 20000,
    },
  });

  const { data: userLockInfo12M } = useReadContract({
    address: synthrStakingAddress,
    abi: synthrStakingABI,
    functionName: "lockInfo",
    args: [monthInSeconds[12]],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 20000,
    },
  });

  const { data: userLockInfo18M } = useReadContract({
    address: synthrStakingAddress,
    abi: synthrStakingABI,
    functionName: "lockInfo",
    args: [monthInSeconds[18]],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 20000,
    },
  });

  const { data: stakingPendingReward } = useReadContract({
    address: synthrStakingAddress,
    abi: synthrStakingABI,
    functionName: "pendingReward",
    args: [account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  const { data: checkAllowance } = useReadContract({
    address: synthTokenAddress,
    abi: synthTokenABI,
    functionName: "allowance",
    args: [account?.address, synthrStakingAddress],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  const { data: coolDownPeriod } = useReadContract({
    address: synthrStakingAddress,
    abi: synthrStakingABI,
    functionName: "coolDownPeriod",
    args: [account?.address],
    query: {
      refetchOnWindowFocus: "always",
      refetchInterval: 2000,
    },
  });

  useEffect(() => {
    if (account?.isConnected && userInfo?.length > 0) getUserInfo();
    if (userLockInfo50m?.length > 0 && userLockInfo50m[0])
      getUserLockInfo(50, userLockInfo50m);
    if (userLockInfo6M?.length > 0 && userLockInfo6M[0])
      getUserLockInfo(6, userLockInfo6M);
    if (userLockInfo9M?.length > 0 && userLockInfo9M[0])
      getUserLockInfo(9, userLockInfo9M);
    if (userLockInfo12M?.length > 0 && userLockInfo12M[0])
      getUserLockInfo(12, userLockInfo12M);
    if (userLockInfo18M?.length > 0 && userLockInfo18M[0])
      getUserLockInfo(18, userLockInfo18M);
  }, [
    account?.isConnected,
    account?.address,
    userInfo,
    userLockInfo50m,
    userLockInfo6M,
    userLockInfo9M,
    userLockInfo12M,
    userLockInfo18M,
  ]);

  useEffect(() => {
    if (account?.isConnected && checkAllowance !== undefined) {
      getAllowance();
    }
  }, [account?.isConnected, account?.address, checkAllowance]);

  useEffect(() => {
    if (coolDownPeriod !== undefined) getCoolDownPeriod();
  }, [account?.address, coolDownPeriod]);

  useEffect(() => {
    if (account?.isConnected && stakingPendingReward != undefined) getUserStakingRewards();
  }, [account?.isConnected, account?.address, stakingPendingReward]);

  const withdrawStakingRewards = async () => {
    await writeContractAsync({
      address: synthrStakingAddress,
      abi: synthrStakingABI,
      functionName: "claim",
      args: [account?.address],
    });
  };

  const getApproval = async (amount) => {
    const price = ethers.utils.parseUnits(String(amount), decimals);
    await writeContractAsync({
      address: synthTokenAddress,
      abi: synthTokenABI,
      functionName: "approve",
      args: [synthrStakingAddress, price],
    });
  };

  const stakeInPool = async (amount, lock_time) => {
    const val = ethers.utils.parseUnits(String(amount), decimals);
    await writeContractAsync({
      address: synthrStakingAddress,
      abi: synthrStakingABI,
      functionName: "deposit",
      args: [val, lock_time],
    });
  };

  const unStake = async () => {
    await writeContractAsync({
      address: synthrStakingAddress,
      abi: synthrStakingABI,
      functionName: "withdrawRequest",
      args: [],
    });
  };

  const withdrawPositionStake = async () => {
    await writeContractAsync({
      address: synthrStakingAddress,
      abi: synthrStakingABI,
      functionName: "withdraw",
      args: [account?.address],
    });
  };

  const getAllowance = async () => {
    const allowanceVal = ethers.utils.formatUnits(checkAllowance, decimals);
    setAllowance(parseFloat(allowanceVal.toString()));
  };

  const getCoolDownPeriod = async () => {
    setCoolDownDays(parseFloat(coolDownPeriod.toString()));
  };

  const getUserInfo = () => {
    const userStakedAmt = ethers.utils.formatUnits(userInfo[0], decimals || 18);
    const userStakedLockType = userInfo[1].toString();
    const userStakedUnlcokEnd = userInfo[2].toString();
    const userStakedRewardDebt = ethers.utils.formatUnits(
      userInfo[3],
      decimals || 18
    );

    const parsedUserStakedAmt = parseFloat(userStakedAmt).toFixed(2);
    const parsedUserStakedRewardDebt =
      parseFloat(userStakedRewardDebt).toFixed(2);
    setUserStakeDetails({
      ...userStakeDetails,
      amount: parsedUserStakedAmt,
      lockType: userStakedLockType.toString(),
      unlockEnd: userStakedUnlcokEnd.toString(),
      rewardDebt: parsedUserStakedRewardDebt.toString(),
    });
  };

  const getUserLockInfo = (period, userLockInfo) => {
    const userLockAmtExist = userLockInfo[0];
    const userLockLastRewardBlock = userLockInfo[1].toString();
    const userLockMaxPoolSize = ethers.utils.formatUnits(
      userLockInfo[2],
      decimals || 18
    );
    const userLockPenalty = userLockInfo[3].toString();
    const userLockCoolDownPeriod = userLockInfo[4].toString();
    const userLockTotalStaked = ethers.utils.formatUnits(
      userLockInfo[5],
      decimals || 18
    );
    const userLockRewardPerBlock = ethers.utils.formatUnits(
      userLockInfo[6],
      decimals || 18
    );
    const userLockAccRewardPerShare = ethers.utils.formatUnits(
      userLockInfo[7],
      decimals || 18
    );
    const userLockEpoch = userLockInfo[8].toString();

    const parsedUserLockMaxPoolSize = parseFloat(userLockMaxPoolSize);
    const parsedUserLockTotalStaked = parseFloat(userLockTotalStaked);
    const parsedUserLockRewardPerBlock = parseFloat(userLockRewardPerBlock);
    const parsedUserLockAccRewardPerShare = parseFloat(
      userLockAccRewardPerShare
    );

    setUserLockDetails((prevState) => ({
      ...prevState,
      [period]: {
        exist: userLockAmtExist,
        lastRewardBlock: userLockLastRewardBlock,
        penalty: userLockPenalty,
        maxPoolSize: parsedUserLockMaxPoolSize,
        coolDownPeriod: userLockCoolDownPeriod,
        totalStaked: parsedUserLockTotalStaked,
        rewardPerBlock: parsedUserLockRewardPerBlock,
        accRewardPerShare: parsedUserLockAccRewardPerShare,
        epoch: userLockEpoch,
      },
    }));
  };

  const getUserStakingRewards = () => {
    const stakingReward = ethers.utils.formatUnits(
      stakingPendingReward,
      decimals || 18
    );

    const parsedStakingReward = parseFloat(stakingReward).toFixed(4);
    setUserStakingReward(parsedStakingReward);
  };

  const resetUserPools = () => {
    setAllowance(0);
    setUserStakingReward(0);
    setCoolDownDays(0);
    setUserStakeDetails({
      amount: 0,
      lockType: 0,
      unlockEnd: 0,
      rewardDebt: 0,
    });
  };

  return {
    allowance,
    coolDownDays,
    userLockDetails,
    userStakingReward,
    userStakeDetails,
    withdrawStakingRewards,
    withdrawPositionStake,
    getApproval,
    stakeInPool,
    unStake,
    resetUserPools,
  };
}
