import { createContext, useEffect } from "react";
import { initialUserState } from "../../utils/initialState";
import { useUserWallet } from "../hooks/useUserWallet";
import { useUserNft } from "../hooks/useUserNfts";
import { useUserPools } from "../hooks/useUserPools";
import { useAccount } from "wagmi";

const UserWalletContext = createContext(initialUserState);

function UserWalletContextProvider({ children }) {
  const account = useAccount();
  const userWallet = useUserWallet();
  const userNft = useUserNft();
  const userPool = useUserPools();

  useEffect(() => {
    userPool?.resetUserPools();
    userNft?.resetUserNfts();
    userWallet?.resetUserWallet();
  }, [account?.isDisconnected]);

  return (
    <UserWalletContext.Provider
      value={{ ...userWallet, ...userNft, ...userPool }}
    >
      {children}
    </UserWalletContext.Provider>
  );
}

export { UserWalletContextProvider, UserWalletContext };
