import { BigNumber } from "bignumber.js";

export function fetchBalancePortion(percent, balance) {
  const balanceBig = new BigNumber(balance);
  const percentBig = new BigNumber(percent);
  const portionBig = balanceBig.times(percentBig).dividedBy(100);
  return portionBig.toString();
}

export function checkBalancePortion(balance, amount) {
  const balanceBig = new BigNumber(balance);
  const amountBig = new BigNumber(amount);
  const actualPercent = amountBig
    .dividedBy(balanceBig)
    .times(100)
    .toFixed(2)
    .toString();
  return actualPercent;
}
