import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ReactComponent as ConnectWalletIcon } from "../assets/staking/walletIcon.svg";

export const ConnectWallet = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            className="buy-synth-button position-relative w-100"
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    className={`cta-button wallet-button`}
                    onClick={openConnectModal}
                    type="button"
                  >
                    <ConnectWalletIcon />
                    CONNECT WALLET
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    className={`cta-button wallet-button`}
                    onClick={openChainModal}
                    type="button"
                  >
                    WRONG NETWORK
                  </button>
                );
              }
              return (
                <div
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   gap: 12,
                  //   border: "None",
                  //   width: "100%",
                  // }}
                  className={`cta-button wallet-button`}
                >
                  <button
                    onClick={openChainModal}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 22,
                          height: 22,
                          borderRadius: 999,
                          overflow: "hidden",
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 22, height: 22 }}
                          />
                        )}
                      </div>
                    )}
                  </button>
                  <button
                    onClick={openAccountModal}
                    type="button"
                    className="wallet-address"
                  >
                    {account.displayName}
                    {/* {account.displayBalance
                      ? ` (${account.displayBalance})`
                      : ""} */}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
