import {ReactComponent as GoldMedal} from "../../assets/goldMedal.svg";
import {ReactComponent as SilverMedal} from "../../assets/silverMedal.svg";
import {ReactComponent as BronzeMedal} from "../../assets/bronzeMedal.svg";
import {ReactComponent as UpDownArrows} from "../../assets/up-down-arrows.svg";
import { useState } from "react";

const Leaderboard = ({users, leaderboard, setUsers, updatedDateAndTime, loading, showNavbar}) => {
  const [sortOrder, setSortOrder] = useState({ totalReward: true, baseReward: true, campRewards: true, badgeReward: true, transactions: true, chains: true, addColSame: true, bridge: true, mint: true, sameChainSwap: true, corssChainSwap: true, burn: true, flagForLiquidation: true, liquidation: true, withdrawCollateral: true, escrowRewards: true, withdrawRewards: true, zealyPoints: true})

  const shortAddress = (address) => {
    const AddressLength = address.length;
    const last4LetterInd = AddressLength - 4;
    const first4Letters = AddressLength > 4 ? address.substring(0, 4) : address;
    const lastLetters = AddressLength > 4 ? address.substring(last4LetterInd, AddressLength) : address;
    return `${first4Letters}....${lastLetters}`;
  }

  const title = (title, sortKey) => {
    return(
      <div className="d-flex align-items-center">
        {title}   
        <div className='up-down-arrows' onClick={() => changeOrder(sortKey)}> <UpDownArrows /> </div>        
      </div>
    )
  }

  const changeOrder = (sortKey) => {
    const newUser = [...users];
    if(sortKey === "rank" || sortKey === "total_reward"){
      if(sortOrder.totalReward){
        newUser.sort((a, b) => a.total_reward - b.total_reward)
        setSortOrder({...sortOrder, totalReward: false})
      }
      else{
        newUser.sort((a, b) => b.total_reward - a.total_reward)
        setSortOrder({...sortOrder, totalReward: true})
      }
    }
    else if(sortKey === "Base Reward"){
      if(sortOrder.baseReward){
        newUser.sort((a, b) => a.reward_int - b.reward_int)
        setSortOrder({...sortOrder, baseReward: false})
      }
      else{
        newUser.sort((a, b) => b.reward_int - a.reward_int)
        setSortOrder({...sortOrder, baseReward: true})
      }
    }

    else if(sortKey === "Campaign rewards"){
      if(sortOrder.campRewards){
        newUser.sort((a, b) => a.camp_reward - b.camp_reward)
        setSortOrder({...sortOrder, campRewards: false})
      }
      else{
        newUser.sort((a, b) => b.camp_reward - a.camp_reward)
        setSortOrder({...sortOrder, campRewards: true})
      }
    }

    else if(sortKey === 'Zealy XP'){
      if(sortOrder.zealyPoints){
        newUser.sort((a, b) => a.zealy_points - b.zealy_points)
        setSortOrder({...sortOrder, zealyPoints: false})
      }
      else{
        newUser.sort((a, b) => b.zealy_points - a.zealy_points)
        setSortOrder({...sortOrder, zealyPoints: true})
      }
    }
    else if(sortKey === 'Badge rewards'){
      if(sortOrder.badgeReward){
        newUser.sort((a, b) => a.badge_reward - b.badge_reward)
        setSortOrder({...sortOrder, badgeReward: false})
      }
      else{
        newUser.sort((a, b) => b.badge_reward - a.badge_reward)
        setSortOrder({...sortOrder, badgeReward: true})
      }
    }
    setUsers(newUser)
  }

  const getRole = (roleNumber) => {
    let role = '';
    switch (roleNumber) {
      case 3:
        role = 'OG'
        break;
      case 2:
        role = 'EA'
        break;
      case 1:
        role = 'Public';
        break;
      default:
        role = 'Public';
        break;
    }
    return role;
  }

  const getBadge = (badgeName) => {
    let badge = 'None';
    
    switch (badgeName) {
      case 0:
        badge = 'None';
        break;
      case 1:
        badge = 'Recruit';
        break;
      case 2:
        badge = 'Guardian';
        break;
      case 3:
        badge = 'Trooper';
        break;
      case 4:
        badge = 'Crusader';
        break;
      case 5:
        badge = 'Master';
        break;
      case 6:
        badge = 'Wizard';
        break;
      case 7:
        badge = 'Legend';
        break;
      case 8:
        badge = 'Immortal'
        break;
      default:
        badge = 'None'
        break;
    }

    return badge;
  }

  return(
    <div className="leaderborad-wrapper">
      <div className={`d-md-flex align-items-center justify-content-between title-container ${!showNavbar ? 'ps-0' : '' }`}>
        <div className="title"> {leaderboard === 'Campaign Leaderboard' ? "Campaign Leaderboard" : "syCREDITS leaderboard"}  </div>
        {/* {
          updatedDateAndTime &&
          <div className="update-date-and-time">Last updated: {updatedDateAndTime}</div>
        } */}
        {/* <div className="note-wrapper">
          <div className={`step-style completed`}>
            <span className={`ripple wave-span`} />
            <span className={`ripple wave-span`} />
            <span className={`ripple wave-span`} />
          </div>
          Complete at least 1 Testnet transaction and 1 Zealy quest to view syCREDITS
        </div> */}
      </div>
      {
        loading ? 
          <div className="empty-leaderboard-text">Loading...</div>
        :
        users !== null ?
          <div className="table-wrapper">
            <table className={`leaderboard-table ${!showNavbar ? 'open-table' : ''}`}>
              <thead>
                <tr>
                  <td className={`${!showNavbar ? 'ps-0' : '' }`}> 
                    {title("Rank", "rank")}
                  </td>
                  <td> 
                    User 
                  </td>
                  <td> 
                    Role 
                  </td>
                  <td>Badge</td>
                  <td>{title("Referrals", "Referrals")}</td>
                  <td>
                    {title("syCREDITS", "total_reward")}
                  </td>
                  <td>
                    {title("Base rewards", "Base Reward")}
                  </td>
                  <td>{title("Zealy XP", "Zealy XP")}</td>
                  <td>{title('Badge rewards', 'Badge rewards')}</td>
                  {/* <td>
                    {title('Campaign rewards', 'Campaign rewards')}
                  </td> */}
                  <td>{title("Referrer rewards", "Referrer rewards")}</td>
                  <td>{title("Referee rewards", "Referee rewards")}</td>
                </tr>
              </thead>

              <tbody>
              {
                users.length > 0 &&
                users.map((item, index) => {
                  return (
                    <tr key={`${item.discord_tag}-${index}`}>
                      <td className={`${!showNavbar ? 'ps-0' : '' }`}>
                        {users.length === 1 ? <div>-</div> : 
                          (index === 0 ? <GoldMedal /> : index === 1 ? <SilverMedal /> : index === 2 ? <BronzeMedal /> : index + 1)
                        }
                      </td>
                      <td>{item.discord_tag ? item.discord_tag : shortAddress(item.wallet_address[0])}</td>
                      <td>{getRole(item.role)}</td>
                      <td>{item.badge ? getBadge(item.badge) : 'None'}</td>
                      <td>{item.successful_referrals ? item.successful_referrals.toLocaleString('en-US') : 0}</td>
                      <td>{item.total_reward ? item.total_reward.toLocaleString('en-US') : 0}</td>
                      <td>{item.reward_int ? item.reward_int.toLocaleString('en-US') : 0}</td>
                      <td>{item.zealy_points ? item.zealy_points.toLocaleString('en-US') : 0}</td>
                      <td>{item.badge_reward ? (item.badge_reward).toLocaleString('en-US') : 0}</td>
                      {/* <td>{item.camp_reward ? item.camp_reward.toLocaleString('en-US') : 0}</td> */}
                      <td>{item.referrer_reward ? item.referrer_reward.toLocaleString('en-US') : 0}</td>
                      <td>{item.referee_reward ? item.referee_reward.toLocaleString('en-US') : 0}</td>
                    </tr>
                  );
                })
              }
              </tbody>
            </table>
          </div>
          :
          <div className="empty-leaderboard-text">
            {leaderboard === 'Campaign Leaderboard' ? "There is currently no active campaign." : "You currently have no transactions." }
          </div>
      }
    </div>
  )
}
export default Leaderboard;