import { ToastContainer } from "react-toastify";
import Header from "../../components/Header";
import HomeSection from "./HomeSection";
import DashboardSection from "./DashboardSection";
// import RewardSection from "./RewardSection";
// import StakeSection from "./StakeSection";
import "react-toastify/dist/ReactToastify.css";

const Staking = () => {
  return (
    <>
      <Header />
      <HomeSection />
      {/* <StakeSection />
      <RewardSection /> */}
      <DashboardSection />
      <ToastContainer />
    </>
  );
};
export default Staking;
