export const lockDetailsObject = {
  exist: false,
  lastRewardBlock: "0",
  maxPoolSize: "0",
  penalty: "0",
  coolDownPeriod: "0",
  totalStaked: "0",
  rewardPerBlock: "0",
  accRewardPerShare: "0",
  epoch: "0",
};

export const initialUserState = {
  address: "",
  account: {},
  totalSynthStaked: 0,
  userTotalRewards: 0,
  userStakingReward: 0,
  userNftReward: 0,
  allowance: 0,
  coolDownDays: 0,
  nftStakeAmt: 0,
  balance: "0",
  loading: false,
  process: "",
  ownedNft: "",
  userStakeDetails: {
    amount: 0,
    lockType: 0,
    unlockEnd: 0,
    rewardDebt: 0,
  },
  userLockDetails: {
    50: lockDetailsObject, // Minutes
    6: lockDetailsObject,
    9: lockDetailsObject,
    12: lockDetailsObject,
    18: lockDetailsObject,
  },
  resetUserWallet: () => null,
  resetUserNfts: () => null,
  resetUserPools: () => null,
  withdrawStakingRewards: async () => null,
  handleLoading: () => null,
  getApproval: async () => null,
  stakeInPool: async () => null,
  unStake: async () => null,
  withdrawPositionStake: async () => null,
  stakeInNft: async () => null,
  withdrawNftStake: async () => null,
  unStakeInNft: async () => null,
  getNftApproval: async () => null,
};