export function formatNumberWithCommas(number) {
  // Convert number to string
  let numberStr = String(number);

  // Split into integer and decimal parts (if any)
  let parts = numberStr.split(".");
  let integerPart = parts[0];

  // Add commas to the integer part
  let formattedIntegerPart = "";
  for (let i = integerPart.length - 1, count = 0; i >= 0; i--) {
    formattedIntegerPart = integerPart[i] + formattedIntegerPart;
    count++;
    if (count % 3 === 0 && i !== 0) {
      formattedIntegerPart = "," + formattedIntegerPart;
    }
  }

  // Combine integer part with decimal part (if any)
  let formattedNumber;
  if (parts.length > 1) {
    formattedNumber = formattedIntegerPart + "." + parts[1];
  } else {
    formattedNumber = formattedIntegerPart;
  }

  return formattedNumber;
}

export function formatNumber(value) {
  // Check if the value is a number
  if (typeof value !== "number") {
    return value; // Return as is if not a number
  }

  // Define suffixes and their multipliers
  const suffixes = ["", "K", "M", "B"];
  const multipliers = [1, 1e3, 1e6, 1e9];

  // Find the appropriate suffix and multiplier
  let suffixIndex = 0;
  while (
    suffixIndex < suffixes.length - 1 &&
    Math.abs(value) >= multipliers[suffixIndex + 1]
  ) {
    suffixIndex++;
  }

  // Calculate the formatted value
  let formattedValue;
  if (value % multipliers[suffixIndex] === 0) {
    // If value is an exact multiple of the multiplier, remove decimals
    formattedValue = (value / multipliers[suffixIndex]).toFixed(0);
  } else {
    // Otherwise, keep up to two decimal places
    formattedValue = (value / multipliers[suffixIndex]).toFixed(2);
  }

  // Append the suffix to the formatted value
  return formattedValue + suffixes[suffixIndex];
}



export function truncateToTwoDecimalPlaces(num) {
  return Math.floor(num * 100) / 100;
}
