export function getLockInPeriodValue(val) {
  return {
    seconds: Math.floor(new Date() / 1000) + val * 30 * 24 * 60 * 60,
    period: val,
  };
}

export function getSliderValue(timeDiff) {
  return timeDiff;
}

export function getSecondsValue(period) {
  if (period === 50) return 1800;
  return period * 30 * 24 * 60 * 60;
}

export function getRelativeMonths(value) {
  if (value == 1800) return 50;
  return value / (30 * 24 * 60 * 60);
}

export const peroidOption = [
  "1 month",
  "2 months",
  "3 months",
  "4 months",
  "5 months",
  "6 months",
  "7 months",
  "8 months",
  "9 months",
  "10 months",
  "11 months",
  "1 year",
  "1 year 1 month",
  "1 year 2 months",
  "1 year 3 months",
  "1 year 4 months",
  "1 year 5 months",
  "1 year 6 months",
  "1 year 7 months",
  "1 year 8 months",
  "1 year 9 months",
  "1 year 10 months",
  "1 year 11 months",
  "2 years",
  "2 years 1 month",
  "2 years 2 months",
  "2 years 3 months",
  "2 years 4 months",
  "2 years 5 months",
  "2 years 6 months",
  "2 years 7 months",
  "2 years 8 months",
  "2 years 9 months",
  "2 years 10 months",
  "2 years 11 months",
  "3 years",
  "3 years 1 month",
  "3 years 2 months",
  "3 years 3 months",
  "3 years 4 months",
  "3 years 5 months",
  "3 years 6 months",
  "3 years 7 months",
  "3 years 8 months",
  "3 years 9 months",
  "3 years 10 months",
  "3 years 11 months",
  "4 years",
];
