import { ThreeDots } from "react-loader-spinner";
import { truncateToTwoDecimalPlaces } from "../../utils/formatNumberWithCommas";

const IncreaseModal = ({
  show,
  hideModal,
  loading,
  process,
  percetange,
  balance,
  amount,
  handleLockAmount,
  handlePercentage,
  handleIncrease,
}) => {
  return (
    <div className={`extend-increase-modal ${show ? "active" : ""}`}>
      <div className="modal-card">
        <div className="title">Increase stake</div>

        <div className="d-flex justify-content-between">
          <div className="amount-lock">Amount to stake</div>
          <div className="d-flex align-items-center">
            <span className="amount-lock">Wallet balance: </span>
            <span className="wallet-balance">
              &nbsp; {truncateToTwoDecimalPlaces(parseFloat(balance)) || 0}
            </span>
          </div>
        </div>

        <div className="input-wrapper">
          <input
            type="text"
            placeholder="00000.00"
            value={amount}
            onChange={(e) => handleLockAmount(e)}
          />
          <div className="d-flex align-items-center">
            <div
              className={`percentage-box ${percetange === 25 ? "active" : ""}`}
              onClick={() => handlePercentage(25)}
            >
              25%
            </div>
            <div
              className={`percentage-box ${percetange === 50 ? "active" : ""}`}
              onClick={() => handlePercentage(50)}
            >
              50%
            </div>
            <div
              className={`percentage-box ${percetange === 75 ? "active" : ""}`}
              onClick={() => handlePercentage(75)}
            >
              75%
            </div>
            <div
              className={`percentage-box ${percetange === 100 ? "active" : ""}`}
              onClick={() => handlePercentage(100)}
            >
              MAX
            </div>
          </div>
        </div>

        <div className="button-wrapper">
          <button
            className="close-button"
            onClick={hideModal}
            disabled={loading}
          >
            <span>CLOSE</span>
          </button>
          <button
            className="submit-button"
            onClick={handleIncrease}
            disabled={loading}
          >
            {loading && process === "increase" ? (
              <ThreeDots
                visible={true}
                height="18"
                width="60"
                color="#ffffff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass="center"
              />
            ) : (
              <span>INCREASE STAKE</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default IncreaseModal;
