import { connectorsForWallets, getDefaultConfig } from "@rainbow-me/rainbowkit";
import { createConfig, fallback, http, unstable_connector } from "wagmi";
import { arbitrumSepolia } from "wagmi/chains";
import {
  metaMaskWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { injected, walletConnect } from "wagmi/connectors";

// export const config = getDefaultConfig({
//   appName: "Synthr",
//   projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
//   chains: [arbitrumSepolia],
//   transports: {
//     [arbitrumSepolia.id]: fallback([
//       unstable_connector(walletConnect),
//       http("https://sepolia-rollup.arbitrum.io/rpc"),
//     ]),
//   },
// });

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [metaMaskWallet, walletConnectWallet],
    },
  ],
  {
    appName: "Synthr",
    projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? "",
  }
);

export const config = createConfig({
  chains: [arbitrumSepolia],
  transports: {
    [arbitrumSepolia.id]: http(arbitrumSepolia.rpcUrls.default.http[0]),
  },
  connectors,
});