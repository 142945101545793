export const gaugeControllerAddress =
  process.env.REACT_APP_GAUGE_CONTROLLER_CONTRACT_ADDRESS;
export const votingEscrowAddress =
  process.env.REACT_APP_VOTING_ESCROW_CONTRACT_ADDRESS;
export const synthTokenAddress = process.env.REACT_APP_SYNTH_CONTRACT_ADDRESS;
export const synthrStakingAddress =
  process.env.REACT_APP_SYNTHR_STAKING_CONTRACT_ADDRESS;
export const nftStakingAddress =
  process.env.REACT_APP_SYNTHR_NFT_STAKING_CONTRACT_ADDRESS;
export const stCrusaderAddress = process.env.REACT_APP_SYBULL_CONTRACT_ADDRESS;
export const stWizardAddress = process.env.REACT_APP_SYMAXI_CONTRACT_ADDRESS;
export const stLegendAddress = process.env.REACT_APP_SYDIAMOND_CONTRACT_ADDRESS;
export const stImmortalAddress = process.env.REACT_APP_SYHOLD_CONTRACT_ADDRESS;
export const subgraphEndPoint = process.env.REACT_APP_SUBGRAPH_URL;
export const monthInSeconds = {
  50: 1800, // Hours
  6: 15552000,
  9: 23328000,
  12: 31104000,
  18: 46656000,
};
